"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringToDatePipe = void 0;
var core_1 = require("@angular/core");
/**
 * Pipe to transform a string to a date
 */
var StringToDatePipe = /** @class */ (function () {
    function StringToDatePipe() {
    }
    /**
     * Transform a date that is passed as string into a date
     * @param value The date passed as string
     * @returns {Date} The Date object
     */
    StringToDatePipe.prototype.transform = function (value) {
        if (value) {
            return new Date(value);
        }
    };
    StringToDatePipe = __decorate([
        (0, core_1.Pipe)({ name: 'stringToDate' })
    ], StringToDatePipe);
    return StringToDatePipe;
}());
exports.StringToDatePipe = StringToDatePipe;
