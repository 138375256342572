"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupPassInfoComponent = exports.EXCHANGE_INFO = void 0;
var core_1 = require("@angular/core");
var uitpas_api_service_1 = require("../../shared/services/uitpas-api.service");
exports.EXCHANGE_INFO = {
    'YEAR': '{total} keer per jaar inwisselbaar',
    'MONTH': '{total} keer per maand inwisselbaar',
    'WEEK': '{total} keer per week inwisselbaar',
    'DAY': '{total} keer per dag inwisselbaar',
    'QUARTER': '{total} keer per kwartaal inwisselbaar',
    'ABSOLUTE': 'Altijd inwisselbaar'
};
var GroupPassInfoComponent = /** @class */ (function () {
    function GroupPassInfoComponent(uitpasApiService) {
        this.uitpasApiService = uitpasApiService;
        this.searching = false;
    }
    /**
     * Load the grouppass info.
     */
    GroupPassInfoComponent.prototype.getGroupPassInfo = function () {
        var _this = this;
        this.searching = true;
        this.groupPassError = null;
        this.groupPassInfo = null;
        this.uitpasApiService.getGroupPassInfo(this.groupPassNumber)
            .subscribe(function (info) { return _this.groupPassInfo = info; }, function (error) { return _this.setError(error); }, function () { return _this.searching = false; });
    };
    /**
     * Set the error to shown.
     */
    GroupPassInfoComponent.prototype.setError = function (error) {
        if (error.code) {
            this.groupPassError = error.message;
        }
        else {
            this.groupPassError = "Dit is geen geldig UiTPas-nummer.";
        }
        this.searching = false;
    };
    /**
     * Get the exchange info to show.
     * @returns {string}
     */
    GroupPassInfoComponent.prototype.getExchangeInfoForCoupon = function (coupon) {
        if (coupon.exchangeConstraint) {
            return exports.EXCHANGE_INFO[coupon.exchangeConstraint.periodType].replace('{total}', coupon.exchangeConstraint.periodVolume);
        }
        return '';
    };
    GroupPassInfoComponent = __decorate([
        (0, core_1.Component)({
            selector: '#group-pass-info',
            template: require('./group-pass-info.component.html'),
        }),
        __metadata("design:paramtypes", [uitpas_api_service_1.UitpasApiService])
    ], GroupPassInfoComponent);
    return GroupPassInfoComponent;
}());
exports.GroupPassInfoComponent = GroupPassInfoComponent;
