"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routing = exports.appRoutingProviders = void 0;
var router_1 = require("@angular/router");
var group_pass_info_component_1 = require("./group-pass-info/components/group-pass-info.component");
var appRoutes = [
    { path: '', component: group_pass_info_component_1.GroupPassInfoComponent },
];
exports.appRoutingProviders = [];
exports.routing = router_1.RouterModule.forRoot(appRoutes);
